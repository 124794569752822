<template>
  <div>
    <Loading v-if="isLoading" loading-message="轉導中..." :background-color="'#ffffff'"/>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import liff from "@line/liff";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        // Redirect To And Brand Code
        let redirectTo = this.$route.query.redirect_to;
        let brandCode = this.$route.query.brand_code;

        if (!redirectTo || !brandCode) {
          this.$swal('發生錯誤', '網址參數錯誤，請重新嘗試', 'error');
          return;
        }

        // 取得 LIFF Token
        let accessToken = await liff.getAccessToken();

        // 王品接收端網址
        const wowprimeUrl = 'https://wowprime-mobile-web.wowfmsapp.com/signin/lineOA-auth';

        // 組成 query string 並進行 URL 編碼
        let queryParams = "?line_token=" + accessToken + "&brand_code=" + brandCode;

        console.log('queryParams', queryParams);

        const encodedState = encodeURIComponent(queryParams);

        console.log('encodedState', encodedState);

        // 組成最終 URL
        const finalUrl = `${wowprimeUrl}?liff.state=${encodedState}`;

        // 重定向到王品網站
        window.location.href = finalUrl;
      } catch (error) {
        console.error(error);
        this.$swal('發生錯誤', '請重新嘗試', 'error');
      }
    },
  }
}
</script>
